@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom CSS Starts */
@layer base {
  .form-group input.custom-input {
    @apply block w-full text-base bg-transparent  border  appearance-none focus:outline-none rounded-lg transition-all duration-100 ease-out h-[50px] py-[10px] px-[20px]  outline-none focus:shadow-formBlue focus:border-[#005581] border-[#adb8bf];
  }
  .form-group.error input.custom-input {
    @apply border-[#be360c] focus:border-[#be360c] focus:shadow-shadowDanger shadow-shadowDanger;
  }
  .form-group label.custom-label {
    @apply absolute text-gray-500 duration-200 transform top-[10px] left-5 z-10 origin-[0] bg-white pointer-events-none opacity-50 leading-[30px] text-base;
  }
  .form-group.active label.custom-label {
    @apply text-[#005581] opacity-100 text-xs -top-[10px] pl-[5px] pr-[10px];
  }
  .form-group.active.error label.custom-label,
  .form-group.error label.custom-label {
    @apply text-[#be360c];
  }
  /***** for the workspace name *****/
  .input-text-overlay::before {
    content: attr(data-overlay-prefix);
  }

  .input-text-overlay::after {
    background: #0000;
    color: #000;
    display: inline-block;
    content: attr(data-overlay-suffix);
  }

  .input-text-overlay {
    @apply block text-base bg-transparent  border  appearance-none focus:outline-none rounded-lg transition-all duration-100 ease-out h-[50px] py-[10px] px-[20px]  outline-none focus:shadow-formBlue focus:border-[#005581] border-[#adb8bf] absolute w-full overflow-hidden whitespace-nowrap;
  }

  .input-overlay-placeholder {
    color: #a9a8a9;
  }

  .input-overlay {
    z-index: 2;
    @apply bg-transparent;
  }
  /***** to block auto fill in input fields *******/
  @-webkit-keyframes autofill {
    0%,
    100% {
      color: #0b2238;
      background: transparent;
    }
  }
  input.block-auto-fill:-webkit-autofill {
    -webkit-animation-delay: 1s;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }
  /***** to block auto fill in input fields ******/
}
/* Custom CSS Ends */

/* Open Sans Extra Bold*/
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/open-sans/OpenSans-ExtraBold.eot");
  src: url("./assets/fonts/open-sans/OpenSans-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/open-sans/OpenSans-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

/* Open Sans Bold*/
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/open-sans/OpenSans-Bold.eot");
  src: url("./assets/fonts/open-sans/OpenSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/open-sans/OpenSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

/* Open Sans Semi Bold*/
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/open-sans/OpenSans-SemiBold.eot");
  src: url("./assets/fonts/open-sans/OpenSans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/open-sans/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

/* Open Sans Medium*/
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/open-sans/OpenSans-Medium.eot");
  src: url("./assets/fonts/open-sans/OpenSans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/open-sans/OpenSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

/* Open Sans Regular */
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/open-sans/OpenSans-Regular.eot");
  src: url("./assets/fonts/open-sans/OpenSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/open-sans/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/open-sans/OpenSans-Italic.eot");
  src: url("./assets/fonts/open-sans/OpenSans-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/open-sans/OpenSans-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

/* Open Sans Light */
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/open-sans/OpenSans-Light.eot");
  src: url("./assets/fonts/open-sans/OpenSans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/open-sans/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
